<template>
  <section>
    <div class="page-header">
      <h3 class="page-title">
        Discount Parameter
      </h3>
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><router-link class="nav-link" to="/"><span class="menu-title">Dashboard</span></router-link></li>
          <li class="breadcrumb-item">Reference</li>          
          <li class="breadcrumb-item active" aria-current="page">Discount Parameter</li>
        </ol>
      </nav>
    </div>
    <div class="row">
      <div class="col-lg-12 grid-margin stretch-card">
				<div class="card">
					<div class="card-body">
							<b-table 
							responsive 
							show-empty
							:striped="tableConfig.striped"
							:bordered="tableConfig.bordered"
							:borderless="tableConfig.borderless"
							:outlined="tableConfig.outlined"
							:small="tableConfig.small"
							:hover="tableConfig.hover"
							:dark="tableConfig.dark"
							:fixed="tableConfig.fixed"
							:foot-clone="tableConfig.footClone"
							:no-border-collapse="tableConfig.noCollapse"
							:head-variant="tableConfig.headVariant"
							:table-variant="tableConfig.tableVariant"
							:items="data" 
							:fields="fields">
								<template #cell(index)="data">
									{{ data.index + 1 }}
								</template>						
								<template v-slot:cell(created_at)="row">
									{{ normalDateTime(row.item.created_at) }}
								</template>
								<template v-slot:cell(updated_at)="row">
									{{ normalDateTime(row.item.updated_at) }}
								</template>
								<template v-slot:cell(actions)="row">
									<button type="button"  
										class="btn btn-sm btn-warning btn-icon-text"
										@click="openEditModal(row.item.uuid, row.item)">
										Edit
										<i class="mdi mdi-file-check btn-icon-append"></i>
									</button>
									<br>
									<br>

									<button type="button"  
										class="btn btn-sm btn-danger btn-icon-text"
										@click="openDeleteModal(row.item.uuid, row.item)">
										Delete
										<i class="mdi mdi mdi-delete-variant btn-icon-append"></i>
									</button>
									<br>
								</template>							
							</b-table>
					</div>
				</div>
      </div>			
    </div>
  </section>

</template>

<script>
import axios from "axios"
import packageStore from "@/packages/stores"
import packageGlobals from "@/packages/globals"
export default {
  name: 'basicTables',
  data () {
    return {
      titleModal: "",
      modalForm: false,
      stateCrud: "",			
			tableConfig : packageGlobals.table,
      data: [],
      totalRows: null,
      lastPage: null,
      page: 1,
      limit: 10,
      filter: null,
			idKey: null,
      form: {
				name: "",
      },						
      fields: [
        {key: "index", label: "No.", sortable: true, class: "text-center" },
				{key: 'name', label: 'Name', class: "text-center", sortable: true},
      ],
			data404 : []
    }
  },
	methods: {
    normalDateTime: (dateData) => packageGlobals.normalDateTime(dateData),
    callback: function (page) {
      this.$router.push({ query: { page: page.target.ariaPosInSet } })
      var currenPages = page.target.ariaPosInSet == null ? 1 : page.target.ariaPosInSet
      this.getAll('-', currenPages, this.limit)
    },		
    getAll: function (search, page, limit) {
      // let loader = this.$loading.show({
      //   container: this.fullPage ? null : this.$refs.containerMain,
      //   canCancel: false,
      //   onCancel: this.onCancel,
      // })

      packageGlobals.configAxios.headers["Access-Control-Allow-Methods"] = "GET"
			axios.get(`${packageStore.mainApi}/api/web/reference/discount-parameter?search=${search}&page=${page}&limit=${limit}`,packageGlobals.configAxios)
			.then((response) => {
        this.data = response.data.result.data
        this.totalRows = response.data.result.total
        this.lastPage = response.data.result.lastPage
			})				
			
    },
     searchData: function() {
      this.getAll(this.search,this.page, this.limit)      
    },
    openAddModal: function () {
      this.form.name = ""
      this.titleModal = "Add Discount Parameter"
      this.stateCrud = "add"
      this.modalForm = true
    },
    openEditModal: function (id, item) {
      this.titleModal = "Edit Discount Parameter"
      this.stateCrud = "edit"
      this.idKey = id
      this.form.name = item.name
      this.modalForm = true
    },
    openDeleteModal: function (id, item) {
      this.$swal.fire({
        title: "Are you sure you want to delete this data?",
        text: `Data : ${item.name}`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Delete!",
      })
      .then((result) => {
				console.log(result.value);
        if (result.value) {
          packageGlobals.configAxios.headers["Access-Control-Allow-Methods"] = "DELETE"
          axios.delete(`${packageStore.mainApi}/api/web/reference/discount-parameter/${id}/${localStorage.getItem("uuid")}`)
          .then((response) => {
            this.responseAxios(response)
          })
        }
      })
    },		
    sendPayload: function () {
      let checkField = packageGlobals.checkField(
        [
          {value : this.form.name, message : 'field name is required', key:'name'}        
        ]
      )
      var flag = checkField.flag, message = checkField.message
      if (flag == 0) {
        // var loader = this.$loading.show({
        //   container: this.fullPage ? null : this.$refs.containerForm,
        //   canCancel: true,
        //   onCancel: this.onCancel,
        // });

        if (this.stateCrud == "add") {
					this.created_by = localStorage.getItem("uuid")
          packageGlobals.configAxios.headers["Access-Control-Allow-Methods"] = "POST"
          // axios.post(`${packageStore.mainApi}/api/web/reference/discount-parameter`,this.form,packageGlobals.configAxios)					
          axios.post(`${packageStore.mainApi}/api/web/reference/discount-parameter`,this.form)
          .then((response) => {
            this.responseAxios(response)
          })
        } else if (this.stateCrud == "edit") {
					this.updated_by = localStorage.getItem("uuid")					
          packageGlobals.configAxios.headers["Access-Control-Allow-Methods"] = "PUT"
          axios.put(`${packageStore.mainApi}/api/web/reference/discount-parameter/${this.idKey}`,this.form)
          .then((response) => {
            this.responseAxios(response)
          })
        }
        // loader.hide()
      }
      else
      {
        this.$swal.fire({
          title: "Ups, There is a mistake",
          text: message,
          icon: "error",
          confirmButtonText: "Ok",
        });        
      }      
    },
    responseAxios: function (response) {
      if (response.data.status == 200) {
        this.modalForm = false
        this.$swal.fire({
          title: "Success",
          text: response.data.result.message,
          icon: "success",
          confirmButtonText: "Ok",
        })
				this.getAll('-', this.page, this.limit)	
      } else {
        switch (response.data.status) {
          case 401:
            this.$swal.fire({
              title: "Ups, There is a mistake",
              text: response.data.result[0].message,
              icon: "error",
              confirmButtonText: "Ok",
            })
            break
          default:
            break
        }
      }
    },				
	},
	mounted() {
		this.getAll('-', this.page, this.limit)		
	},
}
</script>
<style>
.breadcrumb-item + .breadcrumb-item::before
{
	padding-top: 1px;
}

.breadcrumb-item + .breadcrumb-item
{
	padding-top: 8px;
}

.table.table-borderless tr, .table.table-borderless td, .table.table-borderless th
{
  white-space: pre-wrap;
}
</style>